
import { Component, Vue, Watch } from "vue-property-decorator";
import RevenueListHandler from "./RevenueListHandler.vue";
import { AllPayload, SortQueryPayload } from "@/store/modules";
import { AnyObject } from "../../utility";

@Component({
  components: { RevenueListHandler }
})
export default class RevenueIndex extends Vue {
  limit = 25;

  currentPage: number = 1;

  sort: SortQueryPayload = {
    sort: "total",
    order: "desc"
  };

  colFilter: any = {};

  @Watch("limit")
  onLimitChange(val: number, oldVal: number) {
    if (val !== oldVal) {
      this.load();
    }
  }

  @Watch("colFilter")
  onColFilterChange(
    val: any,
    oldVal: any
  ) {
    if (!val || !oldVal) {
      return;
    }

    const k1 = Object.keys(val),
      k2 = Object.keys(oldVal),
      s: string[] = k1.length >= k2.length ? k1 : k2;

    for (let i = 0; i < s.length; i++) {
      const k = s[i];
      const v1 = val[k];
      const v2 = oldVal[k];
      if (v1 !== v2) {
        console.log("onColFilterChange", v1, v2);
        this.load();
        return;
      }
    }
  }

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  async fetchRender(payload: AllPayload) {
    // await Task.dispatch("all", payload);
  }

  get items(): Array<any> {
    return [];
    // const data = Task.query()
    //   .withAll()
    //   .get();

    // return data;
  }

  get loading(): boolean {
    return true;
    // return !!Task.store().state.entities.task.fetching;
  }

  get total() {
    return 0;
    // return Task.store().state.entities.task.total;
  }

  get numPages(): number {
    const total = this.total;

    if (!total) {
      return 0;
    }
    // console.log('t', Math.ceil(total / this.limit), 1);
    return Math.max(Math.ceil(total / this.limit), 1);
  }

  onPageChange(page: number) {
    this.$nextTick(() =>
      this.fetchRender(
        this.compilePayload({
          offset: Math.floor(this.limit * (page - 1))
        })
      )
    );
  }

  compilePayload(merge: Partial<any> = {}): any {
    return {
      limit: this.limit,
      offset: Math.floor(this.limit * (this.currentPage - 1)),
      ...this.sort,
      ...{ filter: this.colFilter },
      ...merge
    };
  }
}
