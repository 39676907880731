import { VuexModule, Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store'
import { LngLatLike } from 'mapbox-gl';
const getCurrentPosition = async (): Promise<Position> => {
  return new Promise((ok, fail) =>
    navigator.geolocation.getCurrentPosition(ok, fail)
  );
};

// lethbridge
const defaultPosition: [number, number] = [49.694168, -112.832779];

@Module({ name: 'positionModule', namespaced: true, dynamic: true, store })
export class CurrentPositionModule extends VuexModule {
  currentPosition?: LngLatLike;

  // defaultPosition: LngLatLike = [49, -122];

  @Mutation
  SET_CURRENT_POSITION(pos: Pick<Coordinates, "latitude"> & Pick<Coordinates, "longitude">) {
    this.currentPosition = { lng: pos.longitude, lat: pos.latitude };
    console.warn('MUTATED', this.currentPosition );
  }

  @Action
  async getCurrentPosition() {
    try {
      const pos = await getCurrentPosition();
      if (pos) {
        this.SET_CURRENT_POSITION(pos.coords);
        // this.context.commit('currentPosition', pos.coords);
      } else {
        this.SET_CURRENT_POSITION({latitude: defaultPosition[0], longitude: defaultPosition[1]});
      }
    } catch (err) {
      console.error('Error geolocation:', err);
      this.SET_CURRENT_POSITION({latitude: defaultPosition[0], longitude: defaultPosition[1]});
    }
  }

  get position() {
    // console.log('GETTER', this.currentPosition);
    return this.currentPosition || null;
    // return this.context.getters["currentPosition"] || null;
  }
}

export const PositionModule = getModule(CurrentPositionModule)