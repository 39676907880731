import VuexORM from "@vuex-orm/core";
import { MR, MRModule, MRNote } from "../modules/MaintenanceRecord";
import { Task, TaskModule } from "../modules/Task";
import { TaskSchedule, TaskScheduleModule } from "../modules/TaskSchedule";
import { TaskQueue, TaskQueueModule } from "../modules/TaskQueue";
import { Job, JobModule } from "../modules/Job";
import { Technician, TechnicianModule } from "../modules/Technician";
import { Timesheet, TimesheetModule } from "../modules/Timesheet";
import { Revenue, RevenueModule } from "../modules/Revenue";
import { CustomerAddress, Customer, CustomerModule } from "../modules/Customer";
import { Address, AddressModule, Country, Region } from "../modules/Address";
import { Payment, PaymentModule } from "../modules/Payment";
import { GeoLocation, GeoLocationModule } from "../modules/GeoLocation";
import { Part, PartModule } from "../modules/Part";
import { PartCategory, PartCategoryModule } from "../modules/PartCategory";
import { PartRequest, PartRequestModule } from "../modules/PartRequest";
import { PartSold, PartSoldModule } from "../modules/PartSold";
import { Manufacturer } from "../modules/Manufacturer/Manufacturer";
import { Sensor, SensorModule } from "../modules/Sensor";
import ManufacturerModule from "../modules/Manufacturer/ManufacturerModule";
import User from "../modules/User/User";
import UserModule from "../modules/User/UserModule";
import { LabourRate, LabourRateModule } from "../modules/LabourRate";
import { MrEquipment, MrEquipmentModule } from "../modules/MrEquipment";

const database = new VuexORM.Database();

database.register(Job, JobModule);
database.register(Timesheet, TimesheetModule);
database.register(Revenue, RevenueModule);
// database.register(MRTime);
database.register(MR, MRModule);
// no module for mr notes yet
database.register(MRNote);

database.register(Task, TaskModule);
database.register(TaskSchedule, TaskScheduleModule);
database.register(TaskQueue, TaskQueueModule);
database.register(Customer, CustomerModule);
database.register(CustomerAddress);
database.register(Address, AddressModule);
database.register(Country);
database.register(Region);
database.register(Technician, TechnicianModule);
database.register(Payment, PaymentModule);
database.register(GeoLocation, GeoLocationModule);
database.register(Manufacturer, ManufacturerModule);
database.register(Part, PartModule);
database.register(PartCategory, PartCategoryModule);
database.register(PartRequest, PartRequestModule);
database.register(PartSold, PartSoldModule);
database.register(User, UserModule);
database.register(Sensor, SensorModule);
database.register(LabourRate, LabourRateModule);
database.register(MrEquipment, MrEquipmentModule);

export { database };
