import { RepositoryInterface } from "@/store/types";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { axiosProvider } from "@/store/provider";
import { RevenueInterface } from "./Revenue";
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from "@/store/provider/intercept";
import { AllPayload, ApiCollectionResponse } from "@/store/modules";

export class RevenueRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    return this.request.get<RevenueInterface>(`list/tech/${id}`, config);
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    return this.request.get<ApiCollectionResponse<RevenueInterface>>(
      "list/tech",
      params ? { ...config, params } : config
    );
  }

  async findByTech(
    techId: number,
    params?: AllPayload,
    config: AxiosRequestConfig = {}
  ) {
    return this.request.get<RevenueInterface[]>(
      `timebytech/${techId}`,
      params ? { ...config, params } : config
    );
  }
}

const revenueRepository = new RevenueRepository(
  axiosProvider(
    "payments",
    {},
    {
      response: [refreshTokenInterceptorProvider()],
    }
  )
);

export { revenueRepository };
