import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosProvider } from '@/store/provider';
import TaskQueue, { TaskQueueInterface } from './TaskQueue';
import { AllPayload, TaskQueueApiCollection } from '@/store/modules';
import { Collection } from '@vuex-orm/core';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';



export class TaskQueueRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }


  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // throw 'Not implemeted yet';
    // console.log(params, 'all');
    return this.request.get<Array<TaskQueue & { info?: any }>>('queued', params ? { ...config, params } : config);
  }


}

const taskQueueRepository = new TaskQueueRepository(axiosProvider('job', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { taskQueueRepository };