import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { GeoLocation } from './GeoLocation';
import { geoLocationRepository, translateInfluxToGeoLocation, pullTechFromTag } from './repository';
import { normalizeError } from '@/utility';
import { GeoLocationState } from '@/store/types';
import { AllPayload, PaginateQueryPayload } from '../helper-types';
import moment from 'moment';
import { of, forkJoin, from } from 'rxjs';
import { mergeMap, toArray } from 'rxjs/operators';
import { Technician } from '../Technician';


@Module
export class GeoLocationModule extends VuexModule implements GeoLocationState {

  @Action
  async latestByTech(payload: {
    end?: string;
    withTech?: boolean;
  } = { withTech: true }) {

    GeoLocation.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      // const startDate = moment(start);
      // const endDate = moment(end);
      const response = await geoLocationRepository.latestByTech(payload.end);
      const { status, data } = response;
      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }
      // console.log('latestByTech', data);
      if (!data || !data.results || !data.results.length) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const locations = translateInfluxToGeoLocation(data);
      // console.log('latestByTech', locations, payload);
      if (payload.withTech === true) {
        const techIds = pullTechFromTag(data);
        const source$ = from(techIds)
          .pipe(
            mergeMap((techId) => {
              if (!techId) {
                return of(null);
              }

              return Technician.dispatch('findOne', { id: techId })
            }),
            toArray()
          );
        // source$.subscribe(() => ok(null));
        await source$.toPromise();
      }

      await GeoLocation.create({
        data: locations.results
      });
      GeoLocation.commit(state => {
        state.fetching = false;
        state.total = +locations.total;
      });
    } catch (err) {
      console.error(err)
      const e = (normalizeError(err) || err);

      GeoLocation.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }
  }

  @Action
  async byRange(payload: {
    start: string;
    end: string;
    page?: PaginateQueryPayload;
    withTech?: boolean;
  }) {

    GeoLocation.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { start, end, page } = payload;
      // const startDate = moment(start);
      // const endDate = moment(end);
      const response = await geoLocationRepository.byRange(start, end, page);
      const { status, data } = response;

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }
      // console.log('range', data);
      if (!data || !data.results || !data.results.length) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const locations = translateInfluxToGeoLocation(data);

      if (payload.withTech === true) {
        const techIds = pullTechFromTag(data);
        const source$ = from(techIds)
          .pipe(
            mergeMap((techId) => {
              if (!techId) {
                return of(null);
              }

              return Technician.dispatch('findOne', { id: techId })
            })
          );
        // source$.subscribe(() => ok(null));
        await source$.toPromise();
      }

      await GeoLocation.create({
        data: locations.results
      });
      GeoLocation.commit(state => {
        state.fetching = false;
        state.total = +locations.total;
      });
    } catch (err) {
      console.error(err)
      const e = (normalizeError(err) || err);

      GeoLocation.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }
  }

  @Action
  async since(payload: {
    start: string;
    // end: string;
    page?: PaginateQueryPayload;
    withTech?: boolean;
  }) {

    GeoLocation.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });
    try {
      const { start, page } = payload;
      // const startDate = moment(start);
      // const endDate = moment(end);
      const response = await geoLocationRepository.since(start, page);
      const { status, data } = response;

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }
      // console.log('range', data);
      if (!data || !data.results || !data.results.length) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const locations = translateInfluxToGeoLocation(data);

      if (payload.withTech === true) {
        const techIds = pullTechFromTag(data);
        const source$ = from(techIds)
          .pipe(
            mergeMap((techId) => {
              if (!techId) {
                return of(null);
              }

              return Technician.dispatch('findOne', { id: techId })
            })
          );
        // source$.subscribe(() => ok(null));
        await source$.toPromise();
      }

      await GeoLocation.create({
        data: locations.results
      });
      GeoLocation.commit(state => {
        state.fetching = false;
        state.total = +locations.total;
      });
    } catch (err) {
      console.error(err)
      const e = (normalizeError(err) || err);

      GeoLocation.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }
  }

  @Action
  async byTech(payload: AllPayload & { techId: number }) {
    GeoLocation.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await geoLocationRepository.byTech(payload.techId, payload);
      const { status, data } = response;

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results || !data.results.length) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const locations = translateInfluxToGeoLocation(data);
      GeoLocation.insert({
        data: locations.results
      });
      GeoLocation.commit(state => {
        state.fetching = false;
        state.total = +locations.total;
      });
    } catch (err) {
      const e = (normalizeError(err) || err);

      GeoLocation.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }
  }

  @Action
  async all() {
    GeoLocation.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {

      // const data = await geoLocationRepository.all();
      // console.log(data)
      // TODO perform mapping here, not in repository
      // GeoLocation.create({
      //   data: data.results
      // });
      // GeoLocation.commit(state => {
      //   state.fetching = false;
      //   state.total = +data.total;
      // }); 
      const response = await geoLocationRepository.all();
      const { status, data } = response;

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results || !data.results.length) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const locations = translateInfluxToGeoLocation(data);
      GeoLocation.create({
        data: locations.results
      });
      GeoLocation.commit(state => {
        state.fetching = false;
        state.total = +locations.total;
      });
      // const { results, total } = data;
      // Payment.create({
      //   data: results.map(payment => {
      //     if ('ammount' in payment) {
      //       payment['amount'] = payment['ammount'];
      //       delete payment['ammount'];
      //     }
      //     return payment;
      //   })
      // });
    } catch (err) {
      const e = (normalizeError(err) || err);

      GeoLocation.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }
  }
}