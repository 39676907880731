
import { Component, Prop, PropSync } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { Revenue, TimesheetFilterQueryPayload } from "@/store/modules";
import { CDataTableFields } from "@/@types";

@Component({})
export class RevenueList extends mixins(
  DateFormatMixin,
  HeMixin,
  CurrencyFormatMixin
) {
  @Prop({ default: () => [] })
  private items!: Revenue[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => 0 })
  private total!: boolean;

  @Prop({ default: () => true })
  private itemsPerPageSelect!: boolean;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", {
    default: () => ({}),
    type: Object,
  })
  columnFilterValueSync!: TimesheetFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  page = 1;

  fields: CDataTableFields = [
    { key: "tech_name", label: "Name", filter: false },
    // { key: "total", label: "Revenue" },
    { key: "total", label: "Revenue", filter: false },
  ];

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  onColumnFilterChange(val: TimesheetFilterQueryPayload) {
    console.log("onColumnFilterChange", val);
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = val;
  }

  formatRevenue(val: number): string {
    const formatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' });
    return formatter.format(val);
  }
}

export default RevenueList;
