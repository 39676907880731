
import { Job, MR, TaskQueue } from '@/store/modules';
import { PositionModule } from "@/store/modules/dynamic-modules";
import { Collection } from "@vuex-orm/core";
import { LngLatLike } from "mapbox-gl";
import moment from "moment";
import { Observable, from } from "rxjs";
import { filter, map, switchMap, toArray } from "rxjs/operators";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { DateFormatMixin } from "../../../mixins";
import { Task, Technician } from "../../../store/modules";
import { GeoLocation } from "../../../store/modules/GeoLocation";
import { AnyObject } from "../../../utility";
import TechnicianMap, { MapAddress } from "./TechnicianMap.vue";
import ThresholdDropdown from "./ThresholdDropdown.vue";

@Component({
  components: { TechnicianMap, ThresholdDropdown },
})
export default class TechnicianLocation extends mixins(DateFormatMixin) {
  errorMessage: string | null = null;

  loading = true;

  taskLoading = true;
  jobLoading = false;

  currentPosition: LngLatLike = [-112.832779, 49.694168];

  @Prop({ default: () => [-112.832779, 49.694168], type: [Array] })
  defaultPosition!: [number, number] | null;

  markers: Collection<GeoLocation> = [];

  addresses: MapAddress[] = [];
  upcomingAddresses: MapAddress[] = [];
  queuedJobAddresses: MapAddress[] = [];

  showAddressMarkers = false;
  showUpcomingTaskMarkers = false;
  showJobMarkers = true;

  rangeVal: number | string = 1440;

  rangeMin = 0;

  rangeMax = 1440;

  rangeStep = 5;

  technicians: Technician[] = [];

  selected: number[] = [];

  /*offsetOptions = [
    {
      value: 0,
      text: "All time",
    },
    {
      value: 5,
      text: "5 Minutes",
    },
    {
      value: 40,
      text: "40 Minutes",
    },
    {
      value: 60,
      text: "60 Minutes",
    },
    {
      value: 180,
      text: "3 Hours",
    },
    {
      value: 1440,
      text: "1 Day",
    },
  ];*/

  currentOffset = 0;

  refreshTimer?: NodeJS.Timeout;

  lastRefresh?: moment.Moment;

  refreshInterval = 15000;

  isRefreshing = false;

  intervalThreshold = 480;

  async mounted() {
    if (!("geolocation" in navigator)) {
      this.errorMessage = "Geolocation is not available.";
      return;
    }

    this.$nextTick(() => this.initData());
  }

  async processUpcomingTasks() {
    this.taskLoading = true;
    const addresses = await this.upcomingTasks().toPromise();
    // console.log("proc up", addresses);
    this.upcomingAddresses = addresses;
    this.taskLoading = false;

  }

  async processQueuedJobs() {
    this.jobLoading = true;
    const addresses = await this.fetchJobs().toPromise();
    this.queuedJobAddresses = addresses;
    this.jobLoading = false;
  }

  fetchJobs() {
    const source$ = from(
      new Promise<void>((ok, fail) => {
        TaskQueue.dispatch("all", {
          filter: {
            // complete: 1,
            // date: '2024-12-30'
          }
        })

          .then(() => ok())
          .catch(fail);
      })
    );

    const now = moment();

    return source$.pipe(
      switchMap<void, Observable<TaskQueue>>(() => {
        const records = TaskQueue.query().withAllRecursive().get();
        return from(records);
      }),
      filter((taskQueue) => {
        if (!taskQueue.job || !taskQueue.date) {
          return false;
        }

        return now.isSame(taskQueue.date, 'day');
      }),
      //
      map<TaskQueue, MapAddress>((taskQueue) => {
        const { job } = taskQueue;

        if (!job || !job.address) {
          return {
            address: '',
            enablePopup: false,
            popupHtml: '',
            color: "#b7b7b7",
          }
        }
        // const { job } = task;
        const address =
          job && job.address
            ? `${job.address.full_address || ""} ${job.address.city} ${job.address.country ? job.address.country.name : 'Canada'
            }`
            : "";
        const name = `${job.customer?.first_name || ''} ${job.customer?.last_name || ''}`.trim()
        return {
          address,
          enablePopup: true,
          popupHtml: [
            `<strong><a href="#/task/${job.queuedTaskID}">${job.jobNumber} <small>${job.queuedStatus}</small></a></strong>`,
            `${name ? '<br />' : ''}<strong>${name}</strong>`,
            // `<p class="mb-0">${mr.customer_name}</p>`,
            `<p>${address}</p>`,
          ].join(""),
          color: "#32a852",
        };
      }),
      toArray()
    );
  }

  upcomingTasks() {
    const source$ = from(
      new Promise<void>((ok, fail) => {
        Task.dispatch("upcoming", {})
          .then(() => ok())
          .catch(fail);
      })
    );

    return source$.pipe(
      switchMap<void, Observable<Task>>(() => {
        const records = Task.query().withAllRecursive().get();

        return from(records);
      }),
      filter((t) => !!(t.job && t.job.address)),
      //
      map<Task, MapAddress>((task) => {
        const { job } = task;
        const address =
          job && job.address
            ? `${job.address.full_address || ""} ${job.address.city} ${job.address.country ? job.address.country.name : 'Canada'
            }`
            : "";
        return {
          address,
          enablePopup: true,
          popupHtml: [
            `<strong><a href="#/task/${task.ID}">Task #${task.taskNumber}</a></strong>`,
            // `<p class="mb-0">${mr.customer_name}</p>`,
            `<p>${address}</p>`,
          ].join(""),
          color: "#1a6da9",
        };
      }),
      toArray()
    );
  }

  pullAddresses() {
    const source$ = from(
      new Promise<void>((ok, fail) => {
        MR.dispatch("fetchData", {
          filter: {
            // approved: 1,
            // voided: 0
            // paid: 1
          },
        })
          .then(() => ok())
          .catch(fail);
      })
    );

    return source$.pipe(
      switchMap<void, Observable<MR>>(() => {
        const records = MR.all();
        return from(records);
      }),
      filter((mr) => !!mr.address_data),
      //
      map<MR, MapAddress>((mr) => ({
        address: mr.address_data || "",
        enablePopup: true,
        popupHtml: [
          `<strong><a href="#/mr/${mr.id}">MR #${mr.taskNumber}</a></strong>`,
          `<p class="mb-0">${mr.customer_name}</p>`,
          `<p>${mr.address_data}</p>`,
        ].join(""),
      })),
      toArray()
    );
  }

  beforeDestroy() {
    if (this.refreshTimer) {
      console.log("Clearing interval");
      clearInterval(this.refreshTimer);
    }
  }

  lastRefreshSince() {
    if (!this.lastRefresh) {
      return "";
    }
    const d = moment().diff(this.lastRefresh, "seconds");
    return `Refresh ${d} seconds ago`;
  }

  offsetElapse() {
    // currentOffset
    const start = moment().subtract(this.currentOffset, "minute");
    return start.fromNow();
  }

  async refresh() {
    if (this.isRefreshing) {
      return;
    }
    // console.log("Refreshing map");
    this.isRefreshing = true;
    await this.fetchData();
    const techs = Technician.query()
      .has("geoLocations")
      .with("geoLocations")
      .get();
    this.$nextTick(() => {
      this.setTechnicians(techs);
      this.lastRefresh = moment();
      this.isRefreshing = false;
    });
  }

  async initData() {
    // process the upcoming tasks after everything else loads.
    // this is due to the slow load times on the API
    // this.processUpcomingTasks();
    this.processQueuedJobs();

    this.loading = true;
    await this.fetchData();
    await this.updateCurrentLocation();

    const techs = Technician.query()
      .has("geoLocations")
      .with("geoLocations")
      .get();

    this.setTechnicians(techs, true);

    // const addresses = await this.pullAddresses().toPromise();
    // this.addresses = addresses;
    this.addresses = [];

    //   const taskAddresses = await this.upcomingTasks().toPromise();
    // console.log('taskAddresses', taskAddresses);
    this.refreshTimer = setInterval(() => this.refresh(), this.refreshInterval);

    this.loading = false;
  }

  onThresholdChange(val: number) {
    this.intervalThreshold = val;
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }

    this.refresh();
  }

  async onOffsetChange(offset?: number) {
    // console.log("offset", offset, this.currentOffset);
    this.loading = true;
    await this.fetchData();
    this.$nextTick(() => {
      const techs = Technician.query()
        .has("geoLocations")
        .with("geoLocations")
        .get();

      this.setTechnicians(techs);
      this.loading = false;
    });
  }

  async updateCurrentLocation() {
    const pos = await this.getCurrentLocation();

    if (pos) {
      this.currentPosition = pos;
      return;
    }
  }

  async fetchData() {
    if (!this.currentOffset) {
      const latestEnd = moment().subtract(this.intervalThreshold, "minute");
      await GeoLocation.dispatch("latestByTech", {
        end: latestEnd.utc().format(),
        withTech: true,
      });
      return;
    }
    // const end = moment();
    const end = moment().subtract(this.currentOffset, "minute");
    const start = moment(end).subtract(this.intervalThreshold, "minute");
    console.log(
      start.utc().format(),
      end.utc().format(),
      this.intervalThreshold
    );
    await GeoLocation.dispatch("byRange", {
      start: start.utc().format(),
      end: end.utc().format(),
      withTech: true,
    });
  }

  async getCurrentLocation(): Promise<LngLatLike | void> {
    await PositionModule.getCurrentPosition();
    return this.$store.state.positionModule.currentPosition;
  }

  setTechnicians(techs: Technician[], selectAll = false) {
    this.technicians = techs;
    if (selectAll) {
      const s = techs.map((tech) => +tech.ID);
      this.selected = s;
    }

    this.$nextTick(() => this.updateMarker());
  }

  flyToTech(tech: Technician) {
    // currentPosition
    if (tech.geoLocations && tech.geoLocations.length) {
      const geo = tech.geoLocations[0];
      this.currentPosition = { lat: geo.lat, lng: geo.long };
    }
    return false;
  }

  onTechSelect(tech: Technician) {
    const id = tech.ID,
      i = this.selected.indexOf(id);
    if (-1 === i) {
      this.selected.push(id);
      // this.selected[''+id] = id;
      // console.log(this.selected);
      this.$nextTick(() => this.updateMarker());
      return;
    }
    // delete this.selected[''+id];
    this.selected.splice(i, 1);

    this.$nextTick(() => this.updateMarker());
  }

  toggleAddresses() {
    this.showAddressMarkers = !this.showAddressMarkers;
  }

  toggleUpcomingTasks() {
    this.showUpcomingTaskMarkers = !this.showUpcomingTaskMarkers;
  }

  toggleJobs() {
    this.showJobMarkers = !this.showJobMarkers;
  }

  isActive(tech: Technician) {
    const id = tech.ID,
      i = this.selected.indexOf(id);
    return -1 !== i;
  }

  find(): Collection<GeoLocation> {
    const q = GeoLocation.query().orderBy("timestamp", "desc");

    return q.get();
  }

  findLatest(techId: number): Collection<GeoLocation> {
    const q = GeoLocation.query()
      .where("tech_id", techId)
      .orderBy("timestamp", "desc")
      .limit(1);

    return q.get();
  }

  findEarliest(): Collection<GeoLocation> {
    const q = GeoLocation.query().orderBy("timestamp", "asc").limit(1);

    return q.get();
  }

  // @Watch('currentPosition')
  getCenterLatLong(): LngLatLike | null {
    if (!this.currentPosition) {
      return this.defaultPosition;
    }
    return this.currentPosition;
    // return [coords.longitude, coords.latitude];
  }

  setCurrentPositionAsTech(tech: Technician) {
    // this.currentPosition= pos;
    const geo = this.markers.find((val) => val.tech_id === tech.ID);
    if (geo) {
      this.currentPosition = { lat: geo.lat, lng: geo.long };
      this.refresh();
    }
  }

  async updateMarker() {
    const end = moment(),
      start = end.subtract(1, "hour");
    const startU = start.valueOf(),
      endU = end.valueOf();

    const locations = this.selected.map((id) =>
      this.findLocation(id, startU, endU)
    );

    this.markers = locations;
  }

  findLocation(techId: number, startU: number, endU: number) {
    const geo = GeoLocation.query()
      // .where('tech_id', id)
      .where((record: GeoLocation) => {
        return (
          record.tech_id === techId &&
          record.timestamp >= startU &&
          record.timestamp <= endU
        );
      })
      .limit(1)
      .get();

    if (!geo.length) {
      return this.findLatest(techId)[0];
    }

    return geo[0];
  }

  getSelected() {
    return this.selected.map((id) => {
      return Technician.find(id);
    });
  }

  async updateData(_range?: number) {
    const r = _range || +this.rangeVal;

    if (isNaN(r) || !r) {
      console.error("Invalid range value", r);
      return;
    }

    const threshold = 60 * 10; // +- 10 mins
    const start = (r - threshold) * 1000;
    const end = (r + threshold) * 1000;

    const q = GeoLocation.query()
      .orderBy("timestamp", "desc")
      .where("timestamp", (val: number) => {
        return val >= start && val <= end;
      });
    const locations = q.get();
    const filtered: Collection<GeoLocation> = [];
    const index: AnyObject = {};
    locations.forEach((location) => {
      const techId = "" + location.tech_id;

      if (techId in index) {
        return;
      }

      filtered.push(location);
      index[techId] = true;
    });
    this.markers = filtered;
  }

  tid?: NodeJS.Timeout;

  debounceOffsetChange(duration = 1000) {
    this.loading = true;
    if (this.tid) {
      clearTimeout(this.tid);
    }
    this.tid = setTimeout(
      () => this.onOffsetChange(this.currentOffset),
      duration
    );
  }

  next() {
    let val = +this.currentOffset;
    // console.log(val, 'next')
    val = val + this.rangeStep;

    if (val > this.rangeMax) {
      val = this.rangeMax;
    }
    const hasChange = val !== this.rangeMin;
    this.currentOffset = val;

    if (hasChange) {
      this.debounceOffsetChange();
    }
  }

  previous() {
    let val = +this.currentOffset;
    val = val - this.rangeStep;
    if (val < this.rangeMin) {
      val = this.rangeMin;
    }
    // console.log(this.rangeVal, this.rangeStep, val, 'prev')
    const hasChange = val !== this.rangeMin;
    this.currentOffset = val;

    if (hasChange) {
      this.debounceOffsetChange();
    }
  }
}
