import { Model } from '@vuex-orm/core';
import { MR } from '../MaintenanceRecord';
import { Technician } from '../Technician';
import Part from '../Part/Part';
import { PartInterface } from '../Part/Part';
import { TechnicianInterface } from '../Technician/Technician';

export interface MrEquipmentInterface {
  id: number;
  mrID?: number;
  snapshot?: Record<string, string>;
  techID?: number;
  tech_name?: string;
  created_at?: string;
  updated_at?: string;
}

export class MrEquipment extends Model implements MrEquipmentInterface {
  id!: number;
  mrID?: number;
  snapshot?: Record<string, string>;
  techID?: number;
  tech_name?: string;
  created_at?: string;
  updated_at?: string;

  static entity = 'mrEquipment';
  static primaryKey = 'id'

  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.string(null).nullable(),
      updated_at: this.string(null).nullable(),
      tech_name: this.string(null).nullable(),
      techID: this.number(null).nullable(),

      mrID: this.number(null).nullable(),
      snapshot: this.attr({}).nullable(),
      mr: this.belongsTo(MR, 'mrID', 'id'),
    }
  }

  static isSaving() {
    return this.store().state.entities[this.entity].saving;
  }

  static getErrors() {
    return this.store().state.entities[this.entity].error;
  }
}

export default MrEquipment;