import { AllPayload, MrEquipmentApiCollection } from '@/store/modules';
import { axiosProvider } from '@/store/provider';
import { RepositoryInterface } from '@/store/types';
import type { AxiosInstance, AxiosRequestConfig } from 'axios';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';

export type MrEquipmentFlag = 'void' | 'fulfill'| 'order';

export class MrEquipmentRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  // async findOne(id: number, config: AxiosRequestConfig = {}) {
  //   // console.log('MrEquipmentRepository::findOne', id, config);
  //   return this.request.get<MrEquipmentInterface>(`byid/${id}`, config);
  // }

  // async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
  //   return this.request.get<MrEquipmentApiCollection>('list/requests', params ? { ...config, params } : config);
  // }

  async findByMR(recordId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log(params);
    return this.request.get<MrEquipmentApiCollection>(`equipment/${recordId}`, params ? { ...config, params } : config);
  }

  // async findByTech(techId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}) {
  //   return this.request.get<MrEquipmentApiCollection>(`getRequestByTech/${techId}`, params ? { ...config, params } : config);
  // }
}

const mrEquipmentRepository = new MrEquipmentRepository(axiosProvider('mr', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { mrEquipmentRepository };
