import { RouteConfig } from "vue-router";

// Maintenance Record (MR) components
const RevenueIndex = () =>
  import(
    /* webpackChunkName: "timesheet-group" */ "@/pages/revenue/RevenueIndex.vue"
  );
const RevenueListPage = () =>
  import(
    /* webpackChunkName: "timesheet-group" */ "@/pages/revenue/RevenueListPage.vue"
  );
// const TimesheetIndexDetail = () => import(/* webpackChunkName: "timesheet-group" */ '@/pages/timesheet/TimesheetIndexDetail.vue')

const SlidePage = () => import("@/pages/SlidePage.vue");
const UnderConstruction = () =>
  import(
    /* webpackChunkName: "generic-pages-group" */ "@/pages/UnderConstruction.vue"
  );

const routes: RouteConfig = {
  path: "revenue",
  name: "Revenue",
  component: SlidePage,
  redirect: "/revenue/",
  children: [
    {
      path: "/",
      component: RevenueListPage,
      name: "Revenue Report",
      // children: [{
      //   path: '/',
      //   component: TimesheetListHandler,
      //   name: "Timesheet Report"
      // }]
    },
    /*{
      path: ':id',
      component: TaskDetail,
      props: true,
      name: "Timesheet Detail"
    },*/
    {
      path: "*",
      name: "Underconstruction",
      component: UnderConstruction,
    },
  ],
};

export default routes;
