import { Model } from '@vuex-orm/core';
import { Task, Customer } from '@/store/modules';
import { Address } from '../Address';

export interface JobInterface {
  ID: number;
  customerID?: number | null;
  addressID?: number | null;
  billingaddressID?: number | null;
  customer?: Customer | null;
  address?: Address | null;
  billingAddress?: Address | null;
  queuedStatus?: string | null;
  queuedTaskID?: number | null;
  jobNumber?: string | null;
}

export class Job extends Model implements JobInterface {
  ID!: number;

  customerID?: number | null;
  addressID?: number | null;
  billingaddressID?: number | null;

  customer?: Customer | null;
  address?: Address | null;
  billingAddress?: Address | null;

  queuedTaskID?: number | null;
  queuedStatus?: string | null;
  jobNumber?: string | null;

  static entity = 'job';
  static primaryKey = 'ID'

  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      ID: this.attr(null),
      proposalID: this.number(null).nullable(),
      customerID: this.number(null).nullable(),
      addressID: this.number(null).nullable(),
      queuedTaskID: this.number(null).nullable(),
      queuedStatus: this.string(null).nullable(),
      billingaddressID: this.number(null).nullable(),
      departmentID: this.number(null).nullable(),
      retro: this.number(null).nullable(),
      userID: this.number(null).nullable(),
      worktypeID: this.number(null).nullable(),
      assigneduserID: this.number(null).nullable(),
      complete: this.number(null).nullable(),
      datetime: this.string(null).nullable(),
      description: this.string(null).nullable(),
      ponumber: this.string('').nullable(),
      mrnumber: this.string('').nullable(),
      jobNumber: this.string('').nullable(),


      task: this.hasMany(Task, 'jobID', 'ID'),
      address: this.belongsTo(Address, 'addressID', 'ID'),
      billingAddress: this.belongsTo(Address, 'billingaddressID', 'ID'),
      customer: this.belongsTo(Customer, 'customerID', 'ID')
    }
  }
}

export default Job;