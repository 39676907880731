import { MrEquipment } from '@/store/modules';
// import {taskRepository} from './repository';
import { normalizeError } from '@/utility';
import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { mrEquipmentRepository } from './repository';



@Module
export class MrEquipmentModule extends VuexModule {
  // fetching: boolean = false;

  @Action
  async findByMR(payload: { recordId: number }) {
    MrEquipment.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { recordId, ...params } = payload;
      const response = await mrEquipmentRepository.findByMR(recordId, params);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data as unknown as Array<Record<string, unknown>>;
      // todo: create toggle? instead of just inserting
      if (data && data.length) {

        await MrEquipment.insert({
          data: data.map(({ snapshot, ...rest }) => ({
            ...rest,
            snapshot: snapshot && typeof snapshot === 'string' ? JSON.parse(snapshot) : null
          }))
        });
      }

      MrEquipment.commit(state => {
        state.fetching = false;
      });
    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      MrEquipment.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }
  }


}



export default MrEquipmentModule;
// export default getModule(MrEquipmentModule);