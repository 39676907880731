
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { BulletListLoader } from "vue-content-loader";
import { MRCardInfo } from "./MRCardInfo.vue";
import { MRTimeListPrint } from "./MRTimeListPrint.vue";
import { JobCardInfo } from "../job/JobCardInfo.vue";
import {
  MRApproveButton,
  MRVoidButton,
  MRCompleteButton,
  MRPaidButton,
  MRJobInfo,
  MRSignaturePrint,
  MRSummary,
  MRNotes,
  MRStatusCallout,
  MRPartSold,
  MRPartRequest,
  MRPartSoldPrint,
  MRSummaryPrint,
} from "./detail";
import { PaymentList } from "@/pages/payment/PaymentList.vue";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { MR, MRInterface, Task, Payment, Job, MrEquipment } from "@/store/modules";
import { MRSummaryMixin } from "./mixin/MRSummaryMixin";
import { mapState } from "vuex";
import PrintDocument from "@/components/shared/PrintDocument.vue";
// import MRNotes from './detail/MRNotes.vue';
// import {AnyObject} from '@/utility';
// import { RootState } from '@/store/types';
// import { MRJobInfo } from "./detail/MRJobInfo.vue";
// import { MRSignaturePrint } from "./detail/MRSignaturePrint.vue";
// import { MRSummary } from "./detail/MRSummary.vue";
// import { RecursiveCharacterTextSplitter, RecursiveCharacterTextSplitterParams } from './text-splitter';

@Component({
  // computed: {
  //   ...mapState("entities/mr", {
  //     item() {
  //       MR.query().withAllRecursive().whereId(this.id).first();
  //     },
  //   }),
  // },
  components: {
    MRJobInfo,
    MRCardInfo,
    MRTimeListPrint,
    JobCardInfo,
    BulletListLoader,
    MRSignaturePrint,
    MRSummary,
    MRApproveButton,
    MRVoidButton,
    MRCompleteButton,
    MRPaidButton,
    PaymentList,
    MRNotes,
    MRStatusCallout,
    MRPartSold,
    MRPartRequest,
    PrintDocument,
    MRPartSoldPrint,
    MRSummaryPrint,
  },
})
export class MRPrint extends mixins(
  HeMixin,
  DateFormatMixin,
  MRSummaryMixin,
  CurrencyFormatMixin
) {
  @Prop([String, Number])
  readonly id!: string | number;

  // @Prop({default: () => true})
  private loading = true;

  private item: MR | null = null;

  private job: Job | null = null;

  private compiledNotesLength = 0;
  private compiledNotes: string[] = [];

  mounted() {
    this.$nextTick(() => this.fetchRender());
  }

  async fetchRender() {

    await MR.dispatch("findOne", { id: +this.id });
    await Payment.dispatch("findByMR", {
      recordId: +this.id,
    });

    await MrEquipment.dispatch("findByMR", {
      recordId: +this.id,
    });

    const record = this.findRecord() as MRInterface | null;
    // console.log('record', record)
    if (record && record.task && record.task.jobID) {
      await Job.dispatch("findOne", {
        id: +record.task.jobID,
        withCustomer: true,
        withAddress: true,
        withBillingAddress: true,
      });
    }
    this.$nextTick(() => {
      this.update({ loading: false });
    });
  }
  
  // async splitText(notes: string, params: Partial<RecursiveCharacterTextSplitterParams> = {}) {
  //   const splitter = new RecursiveCharacterTextSplitter({
  //     chunkSize: 850,
  //     chunkOverlap: 10,
  //     ...params,
  //   })

  //   return splitter.splitText(notes)
  // }

  remainingNotes(offset = 850) {

    const data = this.compiledNotes.reduce((acc, txt) => {
      acc.len += txt.length;
      if (acc.len >= offset) {
        if(!acc.notes.length) {
          acc.notes.push(txt.substring(offset, txt.length));
        } else {
          acc.notes.push(txt);
        }
      }
      return acc;
    }, {len: 0, notes: [] as string[]});
    return data.notes.filter(v => !!v);
  }

  truncateNotes(offset = 0, maxLength: number | null = null) {
    const data = this.compiledNotes;

    let len = 0, last = 0;
    if (null === maxLength) {
      maxLength = this.compiledNotesLength;
    }

    let pos = 0;
    const c: string[] = [];
    for (let i = 0; i < data.length; i++) {
      const txt = data[i];
      len += txt.length;
      // console.log(`offset: ${offset}, len: ${len}, last: ${last}, txt: ${txt.length}, maxLength: ${maxLength}`)
      if(offset > 0 && len < offset) {
        continue;
      }

      pos = offset ? len - txt.length : 0;

      if (len < maxLength) {
        c.push(txt);
        last = len;
        continue;
      }
      // console.log('Truncate needed', maxLength - last, txt.substring(0, maxLength - last))
      c.push(txt.substring(pos, maxLength - last) + '...');
      c.push('<em class="text-right d-block mr-1">See next page for full notes</em>')
      return c;
    }

    return c;
  }

  get notes() {
    return this.compiledNotes;
  }

  get make() {
    const attr = this.getEquipmentAttribute('make');
    return attr.length ? attr[0] : '';
  }

  get model() {
    const attr = this.getEquipmentAttribute('model');
    return attr.length ? attr[0] : '';
  }

  get serial() {
    const attr = this.getEquipmentAttribute('serial');
    return attr.length ? attr[0] : '';
  }

  getEquipmentAttribute(k: string) {
    if (!this.item || !this.item.equipment || !this.item.equipment.length) {
      return [];
    }

    const makes = this.item.equipment
      .map(({ snapshot }) => snapshot && snapshot[k] ? `${snapshot[k]}`.trim() : null)
      .filter(n => !!n);

    if (!makes.length) {
      return [];
    }

    const dedupe = makes.filter((val, i, arr) => arr.indexOf(val) === i);

    return dedupe;
  }

  formatEquipmentAttribute(k: string, defaultVal: string = '') {
    const attr = this.getEquipmentAttribute(k);
    return attr.length ? attr.join(', ') : defaultVal;
  }

  compileNotes(): string[] {
    const item = this.item as MRInterface;
    if (!item) {
      return [""];
    }

    let data: string[] = [];
    // if (item.task && item.task.description) {
    //   let desc = `${this.htmlDecode(item.task.description)}`.trim();
    //   data.push(desc);
    // }

    if (item.notes && item.notes.length) {
      data = item.notes.reduce((acc, note) => {
        if (note) {
          const n = `${this.htmlDecode(note.note)}`.trim();
          acc.push(n);
        }

        return acc;
      }, data);
    }

    const eq = this.otherEquipment();
    if (eq.length) {
      data.push(eq.join(''));
    }
    return data;
  }

  otherEquipment() {
    const data: string[] = [];
    const attrKeys = [{ k: 'make', v: 'Make' }, { k: 'model', v: 'M/N' }, { k: 'serial', v: 'S/N' }];
    const attrNotes = [];
    for (let i = 0; i < attrKeys.length; i++) {
      const { k, v } = attrKeys[i];
      const values = this.getEquipmentAttribute(k);
      if (values.length > 1) {
        attrNotes.push(`<strong class="text-blue">${v}:</strong> ${values.join(', ')}`)
      }
    }

    if (attrNotes.length) {
      data.push(`<span class="note-sub-heading ">Other equipment</span>${attrNotes.join('<br />')}`);
    }

    return data;
  }

  // print() {
  //   // console.log(this.$htmlToPaper);
  //   if (this.item) {
  //     // this.$htmlToPaper(`mr-main-container-${this.item.id}`);
  //     window.print();
  //   }
  // }

  async update(data: { loading: boolean }) {
    this.loading = data.loading;
    this.item = this.findRecord();
    const record = this.item as MRInterface | null;
    if (record && record.task && record.task.job) {
      this.job = record.task.job;
    }

    let notes = this.compileNotes();
    const totalLen = this.compiledNotesLength = notes.reduce((acc, txt) => (acc += txt.length, acc), 0)

    if (totalLen >= 850) {
      const n = notes.join("\n\n");
      // notes = await this.splitText();
      // @see https://stackoverflow.com/questions/7033639/split-large-string-in-n-size-chunks-in-javascript/29202760
      notes = (n.match(/(.|[\r\n]){1,2000}/g) ?? []).map(n => n.trim());
    }

    this.compiledNotes = notes;
    // if (this.item && 'taskID' in this.item) {
    //   console.log(
    //     Task.query()
    //       .withAll()
    //       .whereId(+this.item['taskID'])
    //       .first()
    //   );
    // }
    // console.log("update-print", this.item);
  }

  get fetching(): boolean {
    if (this.loading) {
      return this.loading;
    }
    return !!MR.store().state.entities.mr.fetching;
  }

  get totalApprovedPayment() {
    if (!this.item) {
      return "None";
    }
    const t = this.calcTotalApprovePayment(this.item);
    if (!t) {
      return "None";
    }
    return this.formatLocaleCurrency(t);
  }

  get totalPayment() {
    if (!this.item) {
      return "None";
    }
    const t = this.calcTotalPayment(this.item);
    if (!t) {
      return "None";
    }
    return this.formatLocaleCurrency(t);
  }

  get totalRemaining() {
    if (!this.item) {
      return 0;
    }
    const t = this.calcTotalApprovePayment(this.item);
    return this.formatLocaleCurrency((this.item.price || 0) * 1.05 - t);
  }

  get mrId() {
    if (!this.item) {
      return "";
    }
    return `${this.item.id}`.padStart(6, "0");
  }

  findRecord(): MR | null {
    return MR.query()
      .withAllRecursive()
      .whereId(+this.id)
      .first();
  }

  // @Watch('record')
  // onRecordChange() {

  // }

  // getItem() {
  //   return this.$store.getters
  // }
}

export default MRPrint;
