import { Model } from '@vuex-orm/core';
import { TechnicianInterface } from '../Technician/Technician';
import { JobInterface, Job } from '../Job';

export interface TaskQueueInterface {
  ID: number;
  techID?: number | null;
  taskID?: number | null;
  customerID?: number | null;
  addressID?: number | null;
  queueID?: number | null;
  durationHours?: number | null;
  durationMinutes?: number | null;
  description?: string | null;
  complete?: boolean;
  start?: string | null;
  end?: string | null;
  info?: string | null;
  jobId?: string | null;
  job?: JobInterface | null;

  // technician?: TechnicianInterface;
}

export class TaskQueue extends Model implements TaskQueueInterface {
  ID!: number;
  created?: string | null | undefined;
  createdBy?: string | null | undefined;
  date?: string | null | undefined;
  description?: string | null | undefined;
  techID?: number | null | undefined;
  taskID?: number | null | undefined;
  scheduled?: boolean | undefined;  
  jobID?: number | null | undefined;
  job?: JobInterface | undefined;

  
  static entity = 'taskQueue';
  static primaryKey = 'ID'
  
  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      ID: this.attr(null),
      created: this.string(null).nullable(),
      createdBy: this.string(null).nullable(),
      date: this.string(null).nullable(),
      description: this.string(null).nullable(),
      taskID: this.number(null).nullable(),
      techID: this.number(null).nullable(),      
      scheduled: this.boolean(null).nullable(),     
       jobID: this.number(null).nullable(),
       job: this.belongsTo(Job, 'jobID', 'ID'),

    }
  }
}

export default TaskQueue;