
import { Component, Vue, Watch, PropSync } from "vue-property-decorator";

@Component
export default class DateRangeFilter extends Vue {

  @PropSync("dateRange", { default: () => ({}), type: Object })
  dateRangeSync!: { startDate: string, endDate: string };

  setStartDateFilter(e: Event) {
    if (!e.target) {
      return;
    }
    // const s = { ...this.dateRangeSync };
    this.dateRangeSync.startDate = (e.target as HTMLInputElement).value;

    // this.dateRangeSync = s;

    this.$emit("date-select",  this.dateRangeSync);

  }

  setEndDateFilter(e: Event) {
    if (!e.target) {
      return;
    }

    // const s = { ...this.dateRangeSync };
    this.dateRangeSync.endDate = (e.target as HTMLInputElement).value;
    // this.dateRangeSync = s;
    this.$emit("date-select",  this.dateRangeSync);
  }

}
