import { Model } from '@vuex-orm/core';
import { MR } from '../MaintenanceRecord';
import { Technician } from '../Technician';
import Task from '../Task/Task';
import { TechnicianInterface } from '../Technician/Technician';
import { TaskQueue } from '../TaskQueue';

export interface TaskScheduleInterface {
  ID: number;
  techID?: number | null;
  taskID?: number | null;
  customerID?: number | null;
  addressID?: number | null;
  queueID?: number | null;
  durationHours?: number | null;
  durationMinutes?: number | null;
  description?: string | null;
  complete?: boolean;
  start?: string | null;
  end?: string | null;
  info?: string | null;

  technician?: TechnicianInterface;
}

export class TaskSchedule extends Model implements TaskScheduleInterface {
  ID!: number;
  techID?: number | null | undefined;
  taskID?: number | null | undefined;
  customerID?: number | null | undefined;
  addressID?: number | null | undefined;
  queueID?: number | null | undefined;
  durationHours?: number | null | undefined;
  durationMinutes?: number | null | undefined;
  description?: string | null | undefined;
  complete?: boolean | undefined;
  start?: string | null | undefined;
  end?: string | null | undefined;
  // info?: string | null | undefined;

  technician?: TechnicianInterface;
  queue?: TaskQueue;

  static entity = 'taskSchedule';
  static primaryKey = 'ID'

  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      ID: this.attr(null),

      taskID: this.number(null).nullable(),
      customerID: this.number(null).nullable(),
      addressID: this.number(null).nullable(),
      techID: this.number(null).nullable(),
      queueID: this.number(null).nullable(),
      durationHours: this.number(null).nullable(),
      durationMinutes: this.number(null).nullable(),
      description: this.string(null).nullable(),
      start: this.string(null).nullable(),
      end: this.string(null).nullable(),
      // info: this.string(null).nullable(),
      complete: this.boolean(null).nullable(),

      technician: this.belongsTo(Technician, 'techID', 'ID'),
      task: this.belongsTo(Task, 'taskID', 'ID'),
      queue: this.belongsTo(TaskQueue, 'queueID', 'ID')

    }
  }
}

export default TaskSchedule;