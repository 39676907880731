import { Address, AddressInterface, AllPayload, Customer, CustomerInterface, Job, JobInterface, TaskQueue, TaskQueueInterface } from '@/store/modules';
import { TaskQueueState } from '@/store/types';
// import {taskRepository} from './repository';
import { normalizeError } from '@/utility';
import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { taskQueueRepository } from './repository';

@Module
export class TaskQueueModule extends VuexModule implements TaskQueueState {
 

  @Action
  async all(payload: AllPayload) {
    TaskQueue.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await taskQueueRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      const queue: TaskQueueInterface[] = [];
      const jobs: JobInterface[] = [];
      const addresses: AddressInterface[] = [];
      const customers: CustomerInterface[] = [];

      data.forEach(({ info, taskID, ...rest }) => {
        if (!info || !info.Address || !info.Job) {
          return;
        }

        const q = {...rest, taskID, jobID: info.Job.ID }
        queue.push(q)
        jobs.push({
          ...info.Job,
          queuedTaskID: taskID || null,
          queuedStatus: info.status
        });
        addresses.push(info.Address);
        customers.push(info.Customer);
      });

      await TaskQueue.create({
        data: queue
      });

      await Job.create({
        data: jobs
      });

      await Customer.insert({
        data: customers
      });

      await Address.insert({
        data: addresses
      });


       TaskQueue.commit(state => {
        state.fetching = false;
        state.total = +queue.length;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      TaskQueue.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }

  // @Action
  // async findOne(payload: { id: number }) {
  //   // this.context.commit('fetching', true);
  //   TaskQueue.commit(state => {
  //     state.fetching = true;
  //     state.error.length = 0;
  //   });

  //   try {
  //     const response = await taskQueueRepository.findOne(payload.id);

  //     if (response.status < 200 && response.status >= 300) {
  //       console.error('Invalid 2XX response status');
  //       throw `Invalid response code: ${response.status}`;
  //     }

  //     const data = response.data;

  //     await TaskQueue.insert({
  //       data
  //     });

  //     TaskQueue.commit(state => {
  //       state.fetching = false;
  //     });

  //   } catch (err) {
  //     console.error(err);
  //     // normalizeError
  //     const e = (normalizeError(err) || err);
  //     TaskQueue.commit(state => {
  //       state.fetching = false;
  //       state.error.push(e);
  //     });
  //   }

  //   // this.context.commit('fetching', false);
  // }

}

export default TaskQueueModule;
// export default getModule(TaskQueueModule);