import { Model } from "@vuex-orm/core";

export interface RevenueInterface {
  techID: number;
  tech_name: string;
  total: number;
}

export class Revenue extends Model implements RevenueInterface {
  techID!: number;
  tech_name!: string;
  total!: number;

  static entity = "revenue";
  static primaryKey = "techID";

  static state() {
    return {
      total: 0,
      fetching: false,
      error: [],
      revenue: 0,
    };
  }

  static fields() {
    return {
      techID: this.number(null),
      tech_name: this.string(null),
      total: this.number(null),
      i: this.number(null).nullable(),
    };
  }
}
