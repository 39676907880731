import { VuexModule, Module, Action } from "vuex-module-decorators";
import { normalizeError } from "@/utility";
import {
  AllPayload,
  Revenue,
  RevenueInterface,
  RevenueRepository,
  revenueRepository,
} from "@/store/modules";

@Module
export class RevenueModule extends VuexModule {
  @Action
  async findByTech(payload: { techId: number } & AllPayload) {
    Revenue.commit((state) => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { techId, ...filters } = payload;
      const response = await revenueRepository.findByTech(techId, filters);

      if (response.status < 200 && response.status >= 300) {
        console.error("Invalid 2XX response status");
        throw `Invalid response code: ${response.status}`;
      }

      const data = {
        results: response.data,
        total: response.data.length,
      };

      // todo handle totals?
      if (data.results) {
        Revenue.insert({ data: data.results });
      }

      Revenue.commit((state) => {
        state.fetching = false;
      });
    } catch (err) {
      console.error(err);
      // normalizeError
      const e = normalizeError(err) || err;
      Revenue.commit((state) => {
        state.fetching = false;
        state.error.push(e);
      });
    }
  }

  @Action
  async all(payload: AllPayload) {
    Revenue.commit((state) => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await revenueRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error("Invalid 2XX response status");
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error("Invalid data status codes");
        throw "Invalid response payload.";
      }

      const { results, total } = data;

      console.log(
        "revenue insert",
        data.results.map(({ tech_name }) => tech_name)
      );
      // Timesheet.deleteAll()

      Revenue.create({
        data: results.map((task, i) => {
          // vuex-orm does not preserve the sort
          return { ...task, i };
        }),
      });

      console.log("REVENUE RESULTS:", results);
      console.log("revenue insert AFTER INTERST", Revenue.query().get());

      Revenue.commit((state) => {
        state.fetching = false;
        state.total = +total;
        state.revenue =
          results.reduce((a: number, b: RevenueInterface) => a + b.total, 0) ||
          0;
      });
    } catch (err) {
      console.error(err);
      const e = normalizeError(err) || err;

      Revenue.commit((state) => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }
  }
}

export default RevenueModule;
